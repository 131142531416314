"use client";

import { useState, useEffect } from "react";
import { Globe } from "lucide-react";

export default function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 1,
      bgImage: "assets/img/hero/hero-5-1.webp",
      title: "Somos Lumos",
      text: "Transformamos tu presencia digital con creatividad, innovación y estrategia.",
    },
    // {
    //   id: 2,
    //   bgImage: "assets/img/hero/hero-5-2.webp",
    //   title: "We'r Lumos",
    //   text: "We are digital agency that helps immersive and engaging user experiences that",
    // }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="hero-wrapper hero-5" id="hero">
      <div
        className="global-carousel"
        id="heroSlider5"
        data-fade="true"
        data-slide-show="1"
        data-lg-slide-show="1"
        data-md-slide-show="1"
        data-sm-slide-show="1"
        data-xs-slide-show="1"
        data-arrows="false"
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`hero-slider hero-overlay ${
              index === currentSlide ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${slide.bgImage})` }}
          >
            <div
              className="hero-overlay"
              data-overlay="title"
              data-opacity="5"
            ></div>
            <div className="flex flex-column flex-sm-row h-full items-center justify-center gap-16 px-56">
              <div className="flex flex-column gap-8">
                <h1
                  className="text-[80px] font-extrabold text-white leading-[80%]"
                  data-ani="slideindown"
                  data-ani-delay="0.1s"
                >
                  {slide.title}
                </h1>
                <p
                  className="hero-text"
                  data-ani="slideindown"
                  data-ani-delay="0.2s"
                >
                  {slide.text}
                </p>
              </div>
              <div
                className="bg-[#e3ff04] min-w-[160px] min-h-[160px] rounded-full flex items-center justify-center font-extrabold"
                href="contact.html"
              >
                <span className="text-xl">Hablemos</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
