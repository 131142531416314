import React from 'react'
import Hero from '../components/Landing/Hero'

const Landing = () => {
  return (
<Hero/>
  )
}

export default Landing
